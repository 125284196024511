<template>
  <div class="mt-5 pt-4 mb-4 " style="height: 90%;" >
    <div class="d-flex h-100 justify-content-between flex-column">
      
    <div class=" p-2 text-uppercase font-weight-bold pl-3">
       {{ selectedObject.step_data.name }}
      
    </div>
    <div class="px-3 mt-5 h-100">
        <!-- <span >Content:</span> -->
        <!-- @submit.prevent="validateEmailDetails()" -->
        <div class="d-flex flex-column justify-content-between" style="gap:20px;">
  <div class="d-flex gap-3 flex-column text-uppercase" style="gap: 25px;">
    <div class="">
      <label for="name" class="d-flex w-100 justify-content-between input-label">Name:</label>
      <div id="name" class="delay-time-selection">{{ selectedObject.step_data.name }}</div>
    </div>
    <div class="">
      <label for="Subject-Line" class="d-flex w-100 justify-content-between input-label">Subject Line: </label>
      <div id="Subject-Line" class="delay-time-selection">{{ selectedObject.step_data.subject }}</div>
    </div>
    <div class="">
      <label for="Preview-Text" class="d-flex w-100 justify-content-between input-label">Preview Text: </label>
      <div id="Preview-Text" class="delay-time-selection">{{ selectedObject.step_data.preview_text }}</div>
    </div>
    <div class="">
      <label for="Sender-Email-Address" class="d-flex w-100 justify-content-between input-label">Sender Email Address: </label>
      <div id="Sender-Email-Address" class="delay-time-selection">{{ selectedObject.step_data.sender_email_address }}</div>
    </div>
   
  </div>
  <div class="d-flex justify-content-between">
    <Button class="cancel-button w-100" @click="navigateToEditEmailStep">Edit Email</Button>
  </div>
</div>

       
        </div>

      
    </div>
    
  </div>
</template>

<script>
import Button from '../../../Layout/Button.vue';

export default {
  props:{
    
selectedObject:Object,
isLoading:Boolean
  },
components:{
    Button,
    
},
data(){
  return{
    contentId:null,flowId:null,
    isReplayToEmail:false,
      errors: {
        name: "",
        subject: "",
        preview_text: "",
        sender_name: "",
        sender_email_address: "",
        reply_to_email_address: "",
      },
  }
},
computed:{
  getStepEmailUUID(){
    return this.selectedObject.step_data.uuid
  },
},
 methods:{

  
    navigateToEditEmailStep(){
     this.$emit('edit',this.getStepEmailUUID)
    }
   
  }
}
</script>
<style scoped>
.input-label{
  color: #73738D;

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 12px */
text-transform: uppercase;
}
.delay-time-selection {

  color: #1F1F28;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 14px */
}
.cancel-button{
    background: white!important;
    border: 2px solid  #4D1B7E!important;
    color:#4D1B7E!important;
    font-size: 14px!important;
font-style: normal;
font-weight: 600!important;
}
.overflow-ellipse{
   white-space: nowrap; 
  max-width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
 
}
.max-width{
 max-width: 250px!important;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
</style>
